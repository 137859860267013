<template>
  <swiper
    @click="ads"
    ref="teacher-swiper"
    :options="swiperOptions"
    class="swiper"
  >
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
export default {
  name: "Teacher",
  props: {
    teacherList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        initialSlide: 1,
      },
    };
  },

  // components: { Swiper, SwiperSlide },

  computed: {},

  methods: {
    ads(s) {
      if (!s.target.dataset.tid) return;
      this.$router.push(`/tdetail?tid=${s.target.dataset.tid}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  min-height: 300px;
  position: unset;
  margin-top: 20px;
  .item {
    margin-top: 10px;
    pointer-events: all;
    .item-t {
      background-color: #fff;
      max-width: 192px;
      height: 280px;
      margin: 0 auto;
      text-align: center;
      border-radius: 10px;
      transition: all ease 0.4s;
      user-select: none;
      cursor: pointer;
      padding-top: 1px;
      &:hover {
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
      }

      .teacher-img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin: 29px auto 0;
        overflow: hidden;
        img {
          width: 100%;
          // max-height: 100%;
        }
      }
      p {
        margin: 0;
        max-width: 140px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .t-name {
        font-size: 20px;
        line-height: 52px;
      }
      .t-title {
        font-size: 14px;
      }
      .t-goodat {
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.swiper-wrapper {
  position: unset;
}
.intro-wrapper {
  .swiper-button-next {
    right: -10px;
    &::after {
      color: #fff;
      padding: 10px 5px;
      font-size: 30px;
      background-color: rgba($color: #000000, $alpha: 0.3);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  .swiper-button-prev {
    left: -10px;
    &::after {
      color: #fff;
      padding: 10px 5px;
      font-size: 30px;
      background-color: rgba($color: #000000, $alpha: 0.3);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}
</style>
