<template>
  <div style="background-color: #fafafa; padding-bottom: 50px">
    <div class="carousel-login">
      <!-- DONE:根据登录判断显示 -->
      <Flicking
        :options="{ align: 'prev', circular: true }"
        class="swiper"
        :plugins="plugins"
      >
        <div
          v-for="banner in lists.banner_list"
          :key="banner.id"
          :style="{
            backgroundImage: 'url(' + banner.pic + ')',
          }"
        ></div>
        <div slot="viewport" class="flicking-pagination"></div>
      </Flicking>
      <div class="login-window">
        <div class="logined" v-if="user">
          <div class="logined-img">
            <img
              v-if="user.head_img && user.head_img !== ''"
              :src="'https://mobile.xlteacher.cn' + user.head_img"
            />
            <img v-else src="@/assets/images/default_avator.png" alt="" />
          </div>
          <span class="logined-name">{{ user.name }}</span>
          <button @click="$router.push('/personal')">进入个人中心</button>
        </div>
        <div class="no-login" v-if="!user">
          <div class="logined-img">
            <img src="@/assets/images/default_avator.png" alt="" />
          </div>
          <!-- <p>点击下方登录开始学习吧！</p> -->
          <button @click="loginMask">登录</button>
          <p>
            <span>
              <!-- <el-link :underline="false">还没账号?</el-link> -->
              &ensp;
              <el-link :underline="false" type="primary" @click="openMask">
                立即注册&ensp;
              </el-link>
              <el-link :underline="false" type="info" @click="openForget">
                忘记密码
              </el-link>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="category-recommend">
      <div class="category-course">
        <span class="course-title">新上好课</span>
        <Course t="c" :list="lists.new_course" />
        <!-- <div class="category-box">
          <div
            v-for="type in courseType"
            :key="type.id"
            class="course-item"
            :style="{
              background:
                'url(' + 'http://tot.xlteacher.cn' + type.pic + ') no-repeat',
            }"
            @click="
              $router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`)
            "
          >
            <img :src="`http://tot.xlteacher.cn${type.pic}`" />
            <b>{{ type.type_name }}</b>
          </div>
        </div> -->
      </div>
      <div class="recommend-course">
        <span class="course-title">近期直播</span>
        <Course t="l" :list="lists.new_live" />
      </div>
      <div class="recommend-course">
        <span class="course-title">免费课程</span>
        <Course t="m" :list="lists.free_course" />
      </div>
    </div>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
// import "@egjs/vue-flicking/dist/flicking.css";
import "../assets/css/flicking.min.css";
import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
import "../assets/css/pagination.min.css";

import "animate.css";
import Teacher from "../components/Teacher";
import News from "../components/News";
import Course from "../components/Course.vue";
const plugins = [
  new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false }),
  new Pagination({ type: "bullet" }),
];
export default {
  data() {
    return {
      plugins,
      lists: {},
      userInfo: null, //用户信息,判断是否显示登陆
      bannerList: [],
      courseType: {}, //课程分类数据,
      courseRecommend: {}, //推荐课程数据
      // 新加的
      teacherList: [],
      newsList: {},
      currentPage: 1,
    };
  },

  components: { Flicking, Teacher, News, Course },

  computed: {},
  created() {},
  mounted() {
    this.getIndexData();
  },
  methods: {
    // 首页数据
    getIndexData() {
      this.axios.post("/coursepc/index/index").then((res) => {
        this.lists = res.data;
      });
    },
    openMask() {
      this.$store.state.registerMethod();
    },
    closeMask() {
      this.maskShow = false;
      this.registerShow = false;
      for (let i in this.resgisterForm) {
        this.resgisterForm[i] = "";
      }
    },
    // 登陆弹窗
    loginMask() {
      this.$store.state.loginMethods();
    },
    // 忘记密码弹窗
    openForget() {
      this.$store.state.forgetPwdMethod();
    },
  },
  computed: {
    user() {
      if (JSON.stringify(this.$store.state.cloudPc) !== "{}") {
        console.log(1);
        return JSON.parse(this.$store.state.cloudPc);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-login {
  position: relative;

  .swiper {
    height: 360px;
    div {
      width: 100%;
    }
  }
  .login-window {
    width: 240px;
    height: 310px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    right: 21%;
    transform: translateY(-50%);
    z-index: 999;
    text-align: center;
    .no-login,
    .logined {
      .logined-img {
        width: 63px;
        height: 63px;
        border-radius: 50%;
        overflow: hidden;
        margin: 40px auto 24px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #aca9a9;
      }
      button {
        width: 178px;
        height: 35px;
        background: #8bb9ff;
        opacity: 1;
        border-radius: 22px;
        color: #ffffff;
        font-size: 14px;
        border: 0;
        cursor: pointer;
        margin-top: 54px;
        margin-bottom: 11px;
      }
      span {
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
      }
      .logined-company {
        margin-top: 11px;
      }
    }
  }
}
.category-recommend {
  max-width: 1200px;
  margin: 27px auto 0;
  .course-title {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    display: block;
  }
  .category-course {
    & > a {
      font-size: 18px;
      float: right;
    }
  }
  .recommend-course {
    & > a {
      font-size: 18px;
      float: right;
    }
    .recommend-box {
      padding: 24px 0;
      box-sizing: border-box;
      .recommend-item {
        display: inline-block;
        width: 284px;
        height: 170px;
        margin-right: 21px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        .recommend-item-img {
          height: 172px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .recommend-item-text {
          color: #333333;
          font-size: 16px;
          padding: 0 4px;
          margin: 0;
          margin-top: 13px;
          overflow: hidden;
          &::after {
            content: "";
            display: block;
            clear: both;
          }
          .text-title {
            float: left;
          }
          .text-time {
            float: right;
          }
        }
      }
    }
  }
}
.teacher-intro,
.news-infos {
  box-sizing: border-box;
  background-color: #f8f8f8;
  .course-title {
    font-size: 18px;
    color: #333333;
    position: relative;
    left: -20px;
  }
  .intro-wrapper,
  .news-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .el-pagination {
      text-align: center;
      margin-top: 36px;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}
</style>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.mask {
  .login-mask,
  .forget-mask {
    .el-input__inner {
      border-radius: 50px;
    }
    .el-button {
      border-radius: 50px;
      background-color: #8bb9fc;
      border: 0;
      color: #fff;
    }
  }
}
.register {
  .el-autocomplete {
    position: absolute;
    top: 13%;
    left: 10%;
    height: 110%;
    .el-input {
      height: 68%;
    }
    input {
      width: 120%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 16px;
      padding: 0;
      padding-left: 5px;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
